import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeliveryFieldsFragment = { __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliveryDeliveredDate?: any | null, deliverySlipNumber: string, isActive: boolean, driverNote: string, status: Types.DeliveryStatus, hasPostDeliveryCorrections: boolean, deliverySlipEmailSent: boolean, type: Types.DeliveryType, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, market?: { __typename?: 'MarketEntity', id: number } | null, driver?: { __typename?: 'UserEntity', id: number } | null };

export type SingleDeliveryProductFieldsFragment = { __typename?: 'DeliveryProductEntity', id: number, targetQuantity?: number | null, actualQuantity?: number | null, returnQuantity?: number | null, product: { __typename?: 'ProductEntity', id: number } };

export type DeliveryWithDeliveryProductsFieldsFragment = { __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliveryDeliveredDate?: any | null, deliverySlipNumber: string, isActive: boolean, driverNote: string, status: Types.DeliveryStatus, hasPostDeliveryCorrections: boolean, deliverySlipEmailSent: boolean, type: Types.DeliveryType, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, deliveryProducts?: Array<{ __typename?: 'DeliveryProductEntity', id: number, targetQuantity?: number | null, actualQuantity?: number | null, returnQuantity?: number | null, product: { __typename?: 'ProductEntity', id: number } }> | null, market?: { __typename?: 'MarketEntity', id: number } | null, driver?: { __typename?: 'UserEntity', id: number } | null };

export type DeliveryWithDeliveryProductsAndProcessedDataFieldsFragment = { __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliveryDeliveredDate?: any | null, deliverySlipNumber: string, isActive: boolean, driverNote: string, status: Types.DeliveryStatus, hasPostDeliveryCorrections: boolean, deliverySlipEmailSent: boolean, type: Types.DeliveryType, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, photos?: Array<{ __typename?: 'DeliveryPhotoEntity', id: number }> | null, processedDelivery?: { __typename?: 'ProcessedDeliveryEntity', deliveredAtMarketDate: any, messageFromMarket: string, signatureName: string } | null, deliveryProducts?: Array<{ __typename?: 'DeliveryProductEntity', id: number, targetQuantity?: number | null, actualQuantity?: number | null, returnQuantity?: number | null, product: { __typename?: 'ProductEntity', id: number } }> | null, market?: { __typename?: 'MarketEntity', id: number } | null, driver?: { __typename?: 'UserEntity', id: number } | null };

export type GetAllDeliveriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllDeliveriesQuery = { __typename?: 'Query', deliveries: Array<{ __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliveryDeliveredDate?: any | null, deliverySlipNumber: string, isActive: boolean, driverNote: string, status: Types.DeliveryStatus, hasPostDeliveryCorrections: boolean, deliverySlipEmailSent: boolean, type: Types.DeliveryType, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, market?: { __typename?: 'MarketEntity', id: number } | null, driver?: { __typename?: 'UserEntity', id: number } | null }> };

export type GetDeliveryByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetDeliveryByIdQuery = { __typename?: 'Query', findDeliveryById: { __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliveryDeliveredDate?: any | null, deliverySlipNumber: string, isActive: boolean, driverNote: string, status: Types.DeliveryStatus, hasPostDeliveryCorrections: boolean, deliverySlipEmailSent: boolean, type: Types.DeliveryType, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, photos?: Array<{ __typename?: 'DeliveryPhotoEntity', id: number }> | null, processedDelivery?: { __typename?: 'ProcessedDeliveryEntity', deliveredAtMarketDate: any, messageFromMarket: string, signatureName: string } | null, deliveryProducts?: Array<{ __typename?: 'DeliveryProductEntity', id: number, targetQuantity?: number | null, actualQuantity?: number | null, returnQuantity?: number | null, product: { __typename?: 'ProductEntity', id: number } }> | null, market?: { __typename?: 'MarketEntity', id: number } | null, driver?: { __typename?: 'UserEntity', id: number } | null } };

export type GetSpecificDeliveriesQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type GetSpecificDeliveriesQuery = { __typename?: 'Query', loadSpecificDeliveries: Array<{ __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliveryDeliveredDate?: any | null, deliverySlipNumber: string, isActive: boolean, driverNote: string, status: Types.DeliveryStatus, hasPostDeliveryCorrections: boolean, deliverySlipEmailSent: boolean, type: Types.DeliveryType, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, deliveryProducts?: Array<{ __typename?: 'DeliveryProductEntity', id: number, targetQuantity?: number | null, actualQuantity?: number | null, returnQuantity?: number | null, product: { __typename?: 'ProductEntity', id: number } }> | null, market?: { __typename?: 'MarketEntity', id: number } | null, driver?: { __typename?: 'UserEntity', id: number } | null }> };

export type GetDeliverySlipPdfQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetDeliverySlipPdfQuery = { __typename?: 'Query', getDeliverySlipPdf: { __typename?: 'ReceiptDocumentPdfDto', type: string, base64: string } };

export type GetCompactDeliverySlipPdfQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetCompactDeliverySlipPdfQuery = { __typename?: 'Query', getCompactDeliverySlipPdf: { __typename?: 'ReceiptDocumentPdfDto', type: string, base64: string } };

export type GetCompactDeliverySlipsCollectionPdfQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type GetCompactDeliverySlipsCollectionPdfQuery = { __typename?: 'Query', getCompactDeliverySlipsCollectionPdf: { __typename?: 'ReceiptDocumentPdfDto', type: string, base64: string } };

export type GetDeliverySlipsCollectionPdfQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type GetDeliverySlipsCollectionPdfQuery = { __typename?: 'Query', getDeliverySlipsCollectionPdf: { __typename?: 'ReceiptDocumentPdfDto', type: string, base64: string } };

export type CreateDeliveryMutationVariables = Types.Exact<{
  input: Types.CreateDeliveryInput;
}>;


export type CreateDeliveryMutation = { __typename?: 'Mutation', createDelivery: { __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliveryDeliveredDate?: any | null, deliverySlipNumber: string, isActive: boolean, driverNote: string, status: Types.DeliveryStatus, hasPostDeliveryCorrections: boolean, deliverySlipEmailSent: boolean, type: Types.DeliveryType, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, market?: { __typename?: 'MarketEntity', id: number } | null, driver?: { __typename?: 'UserEntity', id: number } | null } };

export type UpdateDeliveryMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateDeliveryInput;
}>;


export type UpdateDeliveryMutation = { __typename?: 'Mutation', updateDelivery: { __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliveryDeliveredDate?: any | null, deliverySlipNumber: string, isActive: boolean, driverNote: string, status: Types.DeliveryStatus, hasPostDeliveryCorrections: boolean, deliverySlipEmailSent: boolean, type: Types.DeliveryType, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, photos?: Array<{ __typename?: 'DeliveryPhotoEntity', id: number }> | null, processedDelivery?: { __typename?: 'ProcessedDeliveryEntity', deliveredAtMarketDate: any, messageFromMarket: string, signatureName: string } | null, deliveryProducts?: Array<{ __typename?: 'DeliveryProductEntity', id: number, targetQuantity?: number | null, actualQuantity?: number | null, returnQuantity?: number | null, product: { __typename?: 'ProductEntity', id: number } }> | null, market?: { __typename?: 'MarketEntity', id: number } | null, driver?: { __typename?: 'UserEntity', id: number } | null } };

export type SetDeliveryAsDeliveredMutationVariables = Types.Exact<{
  deliveryId: Types.Scalars['Int']['input'];
}>;


export type SetDeliveryAsDeliveredMutation = { __typename?: 'Mutation', setDeliveryAsDelivered: { __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliveryDeliveredDate?: any | null, deliverySlipNumber: string, isActive: boolean, driverNote: string, status: Types.DeliveryStatus, hasPostDeliveryCorrections: boolean, deliverySlipEmailSent: boolean, type: Types.DeliveryType, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, photos?: Array<{ __typename?: 'DeliveryPhotoEntity', id: number }> | null, processedDelivery?: { __typename?: 'ProcessedDeliveryEntity', deliveredAtMarketDate: any, messageFromMarket: string, signatureName: string } | null, deliveryProducts?: Array<{ __typename?: 'DeliveryProductEntity', id: number, targetQuantity?: number | null, actualQuantity?: number | null, returnQuantity?: number | null, product: { __typename?: 'ProductEntity', id: number } }> | null, market?: { __typename?: 'MarketEntity', id: number } | null, driver?: { __typename?: 'UserEntity', id: number } | null } };

export type SetDeliveryAsCompletedMutationVariables = Types.Exact<{
  input: Types.SetDeliveryAsCompletedInput;
}>;


export type SetDeliveryAsCompletedMutation = { __typename?: 'Mutation', setDeliveryAsCompleted: { __typename?: 'DeliveryEntity', id: number, deliveryDate: any, deliveryDeliveredDate?: any | null, deliverySlipNumber: string, isActive: boolean, driverNote: string, status: Types.DeliveryStatus, hasPostDeliveryCorrections: boolean, deliverySlipEmailSent: boolean, type: Types.DeliveryType, vehicleId?: number | null, createdAt?: any | null, updatedAt?: any | null, photos?: Array<{ __typename?: 'DeliveryPhotoEntity', id: number }> | null, processedDelivery?: { __typename?: 'ProcessedDeliveryEntity', deliveredAtMarketDate: any, messageFromMarket: string, signatureName: string } | null, deliveryProducts?: Array<{ __typename?: 'DeliveryProductEntity', id: number, targetQuantity?: number | null, actualQuantity?: number | null, returnQuantity?: number | null, product: { __typename?: 'ProductEntity', id: number } }> | null, market?: { __typename?: 'MarketEntity', id: number } | null, driver?: { __typename?: 'UserEntity', id: number } | null } };

export type SendDeliverySlipEmailMutationVariables = Types.Exact<{
  input: Types.SendDeliverySlipEmailInput;
}>;


export type SendDeliverySlipEmailMutation = { __typename?: 'Mutation', sendDeliverySlipEmail: boolean };

export type CreateNewDeliveriesFromPlanningMutationVariables = Types.Exact<{
  createNewDeliveriesFromPlanningInput: Types.CreateNewDeliveriesFromPlanningInput;
}>;


export type CreateNewDeliveriesFromPlanningMutation = { __typename?: 'Mutation', createNewDeliveriesFromPlanning: boolean };

export const DeliveryFieldsFragmentDoc = gql`
    fragment DeliveryFields on DeliveryEntity {
  id
  deliveryDate
  deliveryDeliveredDate
  deliverySlipNumber
  isActive
  driverNote
  status
  hasPostDeliveryCorrections
  deliverySlipEmailSent
  type
  vehicleId
  createdAt
  updatedAt
  market {
    id
  }
  driver {
    id
  }
}
    `;
export const SingleDeliveryProductFieldsFragmentDoc = gql`
    fragment SingleDeliveryProductFields on DeliveryProductEntity {
  id
  product {
    id
  }
  targetQuantity
  actualQuantity
  returnQuantity
}
    `;
export const DeliveryWithDeliveryProductsFieldsFragmentDoc = gql`
    fragment DeliveryWithDeliveryProductsFields on DeliveryEntity {
  ...DeliveryFields
  deliveryProducts {
    ...SingleDeliveryProductFields
  }
}
    ${DeliveryFieldsFragmentDoc}
${SingleDeliveryProductFieldsFragmentDoc}`;
export const DeliveryWithDeliveryProductsAndProcessedDataFieldsFragmentDoc = gql`
    fragment DeliveryWithDeliveryProductsAndProcessedDataFields on DeliveryEntity {
  ...DeliveryWithDeliveryProductsFields
  photos {
    id
  }
  processedDelivery {
    deliveredAtMarketDate
    messageFromMarket
    signatureName
  }
}
    ${DeliveryWithDeliveryProductsFieldsFragmentDoc}`;
export const GetAllDeliveriesDocument = gql`
    query GetAllDeliveries {
  deliveries {
    ...DeliveryFields
  }
}
    ${DeliveryFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllDeliveriesService extends Apollo.Query<GetAllDeliveriesQuery, GetAllDeliveriesQueryVariables> {
    override document = GetAllDeliveriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDeliveryByIdDocument = gql`
    query GetDeliveryById($id: Int!) {
  findDeliveryById(id: $id) {
    ...DeliveryWithDeliveryProductsAndProcessedDataFields
  }
}
    ${DeliveryWithDeliveryProductsAndProcessedDataFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDeliveryByIdService extends Apollo.Query<GetDeliveryByIdQuery, GetDeliveryByIdQueryVariables> {
    override document = GetDeliveryByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSpecificDeliveriesDocument = gql`
    query GetSpecificDeliveries($ids: [Int!]!) {
  loadSpecificDeliveries(loadSpecificDeliveriesInput: {deliveryIds: $ids}) {
    ...DeliveryWithDeliveryProductsFields
  }
}
    ${DeliveryWithDeliveryProductsFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSpecificDeliveriesService extends Apollo.Query<GetSpecificDeliveriesQuery, GetSpecificDeliveriesQueryVariables> {
    override document = GetSpecificDeliveriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDeliverySlipPdfDocument = gql`
    query GetDeliverySlipPdf($id: Int!) {
  getDeliverySlipPdf(id: $id) {
    type
    base64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDeliverySlipPdfService extends Apollo.Query<GetDeliverySlipPdfQuery, GetDeliverySlipPdfQueryVariables> {
    override document = GetDeliverySlipPdfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCompactDeliverySlipPdfDocument = gql`
    query GetCompactDeliverySlipPdf($id: Int!) {
  getCompactDeliverySlipPdf(id: $id) {
    type
    base64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCompactDeliverySlipPdfService extends Apollo.Query<GetCompactDeliverySlipPdfQuery, GetCompactDeliverySlipPdfQueryVariables> {
    override document = GetCompactDeliverySlipPdfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCompactDeliverySlipsCollectionPdfDocument = gql`
    query GetCompactDeliverySlipsCollectionPdf($ids: [Int!]!) {
  getCompactDeliverySlipsCollectionPdf(deliveryIds: $ids) {
    type
    base64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCompactDeliverySlipsCollectionPdfService extends Apollo.Query<GetCompactDeliverySlipsCollectionPdfQuery, GetCompactDeliverySlipsCollectionPdfQueryVariables> {
    override document = GetCompactDeliverySlipsCollectionPdfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDeliverySlipsCollectionPdfDocument = gql`
    query GetDeliverySlipsCollectionPdf($ids: [Int!]!) {
  getDeliverySlipsCollectionPdf(deliveryIds: $ids) {
    type
    base64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDeliverySlipsCollectionPdfService extends Apollo.Query<GetDeliverySlipsCollectionPdfQuery, GetDeliverySlipsCollectionPdfQueryVariables> {
    override document = GetDeliverySlipsCollectionPdfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDeliveryDocument = gql`
    mutation CreateDelivery($input: CreateDeliveryInput!) {
  createDelivery(createDeliveryInput: $input) {
    ...DeliveryFields
  }
}
    ${DeliveryFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDeliveryService extends Apollo.Mutation<CreateDeliveryMutation, CreateDeliveryMutationVariables> {
    override document = CreateDeliveryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDeliveryDocument = gql`
    mutation UpdateDelivery($id: Int!, $input: UpdateDeliveryInput!) {
  updateDelivery(id: $id, updateDeliveryInput: $input) {
    ...DeliveryWithDeliveryProductsAndProcessedDataFields
  }
}
    ${DeliveryWithDeliveryProductsAndProcessedDataFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDeliveryService extends Apollo.Mutation<UpdateDeliveryMutation, UpdateDeliveryMutationVariables> {
    override document = UpdateDeliveryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetDeliveryAsDeliveredDocument = gql`
    mutation SetDeliveryAsDelivered($deliveryId: Int!) {
  setDeliveryAsDelivered(deliveryId: $deliveryId) {
    ...DeliveryWithDeliveryProductsAndProcessedDataFields
  }
}
    ${DeliveryWithDeliveryProductsAndProcessedDataFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetDeliveryAsDeliveredService extends Apollo.Mutation<SetDeliveryAsDeliveredMutation, SetDeliveryAsDeliveredMutationVariables> {
    override document = SetDeliveryAsDeliveredDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetDeliveryAsCompletedDocument = gql`
    mutation SetDeliveryAsCompleted($input: SetDeliveryAsCompletedInput!) {
  setDeliveryAsCompleted(setDeliveryAsCompletedInput: $input) {
    ...DeliveryWithDeliveryProductsAndProcessedDataFields
  }
}
    ${DeliveryWithDeliveryProductsAndProcessedDataFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetDeliveryAsCompletedService extends Apollo.Mutation<SetDeliveryAsCompletedMutation, SetDeliveryAsCompletedMutationVariables> {
    override document = SetDeliveryAsCompletedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendDeliverySlipEmailDocument = gql`
    mutation SendDeliverySlipEmail($input: SendDeliverySlipEmailInput!) {
  sendDeliverySlipEmail(sendDeliverySlipEmailInput: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendDeliverySlipEmailService extends Apollo.Mutation<SendDeliverySlipEmailMutation, SendDeliverySlipEmailMutationVariables> {
    override document = SendDeliverySlipEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateNewDeliveriesFromPlanningDocument = gql`
    mutation CreateNewDeliveriesFromPlanning($createNewDeliveriesFromPlanningInput: CreateNewDeliveriesFromPlanningInput!) {
  createNewDeliveriesFromPlanning(
    createNewDeliveriesFromPlanningInput: $createNewDeliveriesFromPlanningInput
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateNewDeliveriesFromPlanningService extends Apollo.Mutation<CreateNewDeliveriesFromPlanningMutation, CreateNewDeliveriesFromPlanningMutationVariables> {
    override document = CreateNewDeliveriesFromPlanningDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }