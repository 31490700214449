<p-table
  #pTable
  selectionMode="single"
  styleClass="p-datatable-gridlines"
  currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
  dataKey="id"
  [value]="rowData"
  [loading]="loading"
  [columns]="tableColumns"
  [rows]="rowData.length"
  [resizableColumns]="true"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[{ showAll: 'Alle' }, 50, 100, 300]"
  [paginator]="true"
  (sortFunction)="customSort($event)"
  [customSort]="true"
  [tableStyle]="{ 'min-width': '75rem' }"
  [selection]="selectedEntity"
  (selectionChange)="onSelectionChange($event)"
  (onFilter)="tableFilter.emit($event)"
>
  <ng-template pTemplate="caption">
    <div class="flex" style="position: fixed;
                             z-index: 99999;
                             right: 226px;
                             top: 23px;">
      <button type="button" pButton icon="pi pi-file-excel"
              class="p-button-secondary mr-2"
              pTooltip="Excel exportieren"
              tooltipPosition="bottom"
              (click)="exportExcel()"></button>
      <button type="button" pButton icon="pi pi-file-pdf"
              class="p-button-secondary mr-2"
              pTooltip="PDF exportieren"
              tooltipPosition="bottom"
              (click)="exportPdf()"></button>
    </div>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns"
          [class]="col.class || ''"
          [width]="col.width || ''"
          [title]="col.tooltipField || ''"
          [pSortableColumn]="col.field"
          [pSortableColumnDisabled]="!col.sortable"
          pResizableColumn>
        {{ col.header }}
        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="rowData.length > 1">
      <th *ngFor="let col of columns">
        <p-columnFilter type="text"
                        placeholder="Filter"
                        [field]="col.field"
                        [matchMode]="DEFAULT_COL_FILTER_MATCH_MODE" />
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row let-columns="columns">
    <tr [pSelectableRow]="row">
      <td *ngFor="let col of columns" [class]="col.class || ''"
          [attr.title]="row[col.tooltipField] ? row[col.tooltipField] : ''">
        <ng-container *ngIf="col.customRender?.renderType; else defaultCellTpl">
          <ng-container [ngSwitch]="col.customRender.renderType">
            <ng-container *ngSwitchCase="PerfoDataTableCellRenderType.DATE">
              <span [innerHTML]="formatDate(row[col.field]) || '- Ohne Datum -'"
                    [ngClass]="{'font-mono': col.customRender?.monoFont}"></span>
            </ng-container>

            <ng-container *ngSwitchCase="PerfoDataTableCellRenderType.PRODUCT_PRICE">
              <span [innerHTML]="formatProductPrice(row, col.field)" [ngClass]="{'font-mono': col.customRender?.monoFont}"></span>
            </ng-container>

            <!-- TODO: Rename RenderType to MONEY-->
            <ng-container *ngSwitchCase="PerfoDataTableCellRenderType.PRICE">
              <span [ngClass]="{'font-mono': col.customRender?.monoFont}">
                 {{ row[col.field] | number:'1.2-2':'de' }}
              </span>
            </ng-container>

            <ng-container *ngSwitchCase="PerfoDataTableCellRenderType.ADDRESS">
              <span [innerHTML]="formatAddress(row[col.field]) || '- Ohne Adresse -'"
                    [ngClass]="{'font-mono': col.customRender?.monoFont}"></span>
            </ng-container>

            <ng-container *ngSwitchCase="PerfoDataTableCellRenderType.MARKET">
              <span [innerHTML]="formatMarket(row[col.field]) || '- Ohne Markt -'"
                    [ngClass]="{'font-mono': col.customRender?.monoFont}"></span>
            </ng-container>

            <ng-container *ngSwitchCase="PerfoDataTableCellRenderType.USER">
              <span [innerHTML]="formatDriver(row[col.field]) || '- Ohne Fahrer -'"
                    [ngClass]="{'font-mono': col.customRender?.monoFont}"></span>
            </ng-container>
          </ng-container>

        </ng-container>
        <ng-template #defaultCellTpl>
          <span [innerHTML]="row[col.field]" [ngClass]="{'font-mono': col.customRender?.monoFont}"></span>
        </ng-template>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">Keine Einträge gefunden.</td>
    </tr>
  </ng-template>
</p-table>

<!--TODO:Refactor: Check for usage of filteredValue -> DOM optimization - see PriemNG table docs -->
