import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { IProduct, PerfoEntityType } from '@prf/shared/domain';
import { CreateProduct, LoadProducts, UpdateProduct } from './products.actions';
import { Observable } from 'rxjs';
import { ToastService } from '../../shared/services/toast/toast.service';
import {
  CreateProductMutation,
  CreateProductService,
  GetAllProductsService,
  ProductFieldsFragment,
  UpdateProductService,
} from '../../graphql/products-operations.generated';
import { MutationResult } from 'apollo-angular';
import { CreateProductInput, UpdateProductInput } from '../../graphql/_types.generated';
import { BaseEntityState } from './base.state';

type LocalModel = IProduct;
type LocalStateModel = ProductsStateModel;
type LocalStateContext = StateContext<ProductsStateModel>;

export interface ProductsStateModel {
  items: LocalModel[];
}

@State<ProductsStateModel>({
  name: 'products',
  defaults: {
    items: [],
  },
})
@Injectable()
export class ProductsState
  extends BaseEntityState<
    {
      fetchAll: GetAllProductsService;
      create: CreateProductService;
      update: UpdateProductService;
    },
    IProduct
  >
  implements NgxsOnInit
{
  protected entityType: PerfoEntityType = 'product';

  private readonly entityPropertiesToPick: Record<keyof IProduct, boolean> = {
    id: true,
    productNo: true,
    category: true,
    description: true,
    pack: true,
    ean: true,
    netPrice: true,
    grossPrice: true,
    vatRate: true,
    uvp: true,

    // Exclude
    recipe: false,
    customPrices: false,
  };

  constructor(
    protected getAllProductsService: GetAllProductsService,
    protected createProductService: CreateProductService,
    protected updateProductService: UpdateProductService,
    toastService: ToastService,
  ) {
    super(
      {
        fetchAll: getAllProductsService,
        create: createProductService,
        update: updateProductService,
      },
      toastService,
    );
  }

  protected getLoadAction(): object {
    return new LoadProducts();
  }

  protected mapGqlEntityToUiEntity(entity: ProductFieldsFragment): LocalModel {
    return {
      id: entity.id,
      category: entity.category,
      description: entity.description,
      ean: entity.ean,
      grossPrice: entity.grossPrice,
      netPrice: entity.netPrice,
      pack: entity.pack,
      productNo: entity.productNo,
      // recipe: entity.recipe,
      uvp: entity.uvp,
      vatRate: entity.vatRate,
      customPrices: entity.customPrices?.map(cp => ({
        id: cp.id,
        netPrice: cp.netPrice,
        grossPrice: cp.grossPrice,
        retailerId: cp.retailer?.id,
        retailerName: cp.retailer?.retailerName,
        marketId: cp.market?.id,
        marketName: cp.market?.marketName,
      })),
    };
  }

  // return type should probably be Partial<IProduct>
  private pickEntityProperties(entity: any): IProduct {
    const product: Partial<IProduct> = {};
    Object.keys(this.entityPropertiesToPick).forEach((key) => {
      if (this.entityPropertiesToPick[key as keyof IProduct] && key in entity) {
        product[key as keyof IProduct] = entity[key];
      }
    });

    return product as IProduct;
  }

  @Selector()
  static items(state: LocalStateModel): LocalModel[] {
    return state.items;
  }

  @Action(LoadProducts)
  protected loadProducts(
    ctx: LocalStateContext,
    action: LoadProducts,
  ): Observable<any> {
    return this.loadEntities<ProductFieldsFragment[]>(ctx, 'products');
  }

  @Action(CreateProduct)
  createProduct(
    ctx: LocalStateContext,
    action: CreateProduct,
  ): Observable<MutationResult<CreateProductMutation>> {
    const product = this.pickEntityProperties(action.payload.entity);
    return this.createEntity<CreateProductInput>(
      ctx,
      product,
      'createProduct',
    );
  }

  @Action(UpdateProduct)
  updateProduct(
    ctx: LocalStateContext,
    action: UpdateProduct,
  ): Observable<any> {
    const product = this.pickEntityProperties(action.payload.entity);
    return this.updateEntity<UpdateProductInput>(
      ctx,
      product,
      'updateProduct',
    );
  }
}
