<div class="flex flex-column gap-4 p-4">
  <!-- Month Selection -->
  <div class="flex flex-column gap-2">
    <label>Export für Monat:</label>
    <p-calendar [(ngModel)]="selectedDate" view="month" dateFormat="MM yy" (onSelect)="onYearChange()">
    </p-calendar>
  </div>

  <!-- CSV Export Section -->
  <p-card>
    <div class="flex justify-content-between align-items-center">
      <div>
        <h3 class="m-0 mb-2">Rechnungen als CSV-Export</h3>
        <p class="text-gray-600 m-0">Tabellarischer Export im CSV-Format.</p>
      </div>
      <p-button label="Exportieren" icon="pi pi-download" (onClick)="onExportCsv()"></p-button>
    </div>
  </p-card>

  <!-- PDF Export Section -->
  <p-card>
    <div class="flex justify-content-between align-items-center mb-3">
      <div>
        <h3 class="m-0 mb-2">Rechnungen als PDF-Sammelexport</h3>
<!--        <p class="text-gray-600 m-0">Export als Sammeldokument-PDF.</p>-->
      </div>
<!--      <p-button label="Erstellen" icon="pi pi-file-pdf" (onClick)="onExportPdf()"></p-button>-->
    </div>

    <!-- Loading State -->
    <div *ngIf="loading$ | async" class="flex justify-content-center">
      <h1>Wird geladen...</h1>
      <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
    </div>

    <!-- Error State -->
    <div *ngIf="error$ | async as error" class="p-error mb-3">
      <i class="pi pi-exclamation-circle"></i>
      Fehler beim Laden der Exporte: {{ error.message }}
    </div>

    <!-- Exports List -->
    <div *ngIf="exports$ | async as exports" class="flex flex-column gap-2">
      <div *ngFor="let export of exports" class="flex align-items-center justify-content-between p-2 border-round bg-gray-50">
        <div class="flex align-items-center gap-2">
          <i class="pi pi-file-pdf text-red-600"></i>
          <span>{{ export.filename }}</span>
        </div>
        <div class="flex align-items-center gap-3">
          <span class="text-gray-600 text-sm">
<!--            {{ export.createdAt | date:'dd.MM.yyyy HH:mm' }}-->
          </span>
<!--          <button pButton icon="pi pi-download" class="p-button-text p-button-sm"-->
<!--                  (click)="onDownloadExport(export)"></button>-->
          <button pButton
                  [icon]="isDownloading(export.filename) ? 'pi pi-spin pi-spinner' : 'pi pi-download'"
                  class="p-button-text p-button-sm"
                  [disabled]="!!downloadingFile"
                  (click)="onDownloadExport(export)">
          </button>
        </div>
      </div>
      <p *ngIf="exports.length === 0" class="text-gray-600 text-center p-3">
        Keine PDF-Exporte für diesen Monat verfügbar.
      </p>
    </div>
  </p-card>
</div>
