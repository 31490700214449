import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { AddressFieldsFragmentDoc } from './shared-fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type InvoiceFieldsFragment = { __typename?: 'InvoiceEntity', id: number, invoiceAmount: number, invoiceDate: any, invoiceNumber: string, netDiscount: number, status: Types.InvoiceStatus, createdAt?: any | null, updatedAt?: any | null, invoiceEmailSent: boolean, delivery: { __typename?: 'DeliveryEntity', id: number, deliverySlipNumber: string, deliveryDate: any, market?: { __typename?: 'MarketEntity', id: number, marketName: string, deliveryAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } } | null } };

export type GetAllInvoicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllInvoicesQuery = { __typename?: 'Query', invoices: Array<{ __typename?: 'InvoiceEntity', id: number, invoiceAmount: number, invoiceDate: any, invoiceNumber: string, netDiscount: number, status: Types.InvoiceStatus, createdAt?: any | null, updatedAt?: any | null, invoiceEmailSent: boolean, delivery: { __typename?: 'DeliveryEntity', id: number, deliverySlipNumber: string, deliveryDate: any, market?: { __typename?: 'MarketEntity', id: number, marketName: string, deliveryAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } } | null } }> };

export type GetPersistedInvoicePdfQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetPersistedInvoicePdfQuery = { __typename?: 'Query', getPersistedInvoicePdf: { __typename?: 'ReceiptDocumentPdfDto', type: string, base64: string } };

export type GetInvoicePdfQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetInvoicePdfQuery = { __typename?: 'Query', getInvoicePdf: { __typename?: 'ReceiptDocumentPdfDto', type: string, base64: string } };

export type SendInvoiceEmailMutationVariables = Types.Exact<{
  input: Types.SendInvoiceEmailInput;
}>;


export type SendInvoiceEmailMutation = { __typename?: 'Mutation', sendInvoiceEmail: boolean };

export type ExportInvoicesAsCsvMutationVariables = Types.Exact<{
  startDate: Types.Scalars['DateTime']['input'];
  endDate: Types.Scalars['DateTime']['input'];
}>;


export type ExportInvoicesAsCsvMutation = { __typename?: 'Mutation', exportInvoicesAsCsv: { __typename?: 'InvoiceExportCsvDto', csv: string, filename: string } };

export type GetInvoiceExportsQueryVariables = Types.Exact<{
  year?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetInvoiceExportsQuery = { __typename?: 'Query', getInvoiceExports: Array<{ __typename?: 'InvoiceExportFileDto', filename: string, year: string, month: string, sizeInBytes: number, createdAt: any }> };

export const InvoiceFieldsFragmentDoc = gql`
    fragment InvoiceFields on InvoiceEntity {
  id
  invoiceAmount
  invoiceDate
  invoiceNumber
  netDiscount
  status
  createdAt
  updatedAt
  invoiceEmailSent
  delivery {
    id
    deliverySlipNumber
    deliveryDate
    market {
      id
      marketName
      deliveryAddress {
        ...AddressFields
      }
    }
  }
}
    ${AddressFieldsFragmentDoc}`;
export const GetAllInvoicesDocument = gql`
    query GetAllInvoices {
  invoices {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllInvoicesService extends Apollo.Query<GetAllInvoicesQuery, GetAllInvoicesQueryVariables> {
    override document = GetAllInvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPersistedInvoicePdfDocument = gql`
    query GetPersistedInvoicePdf($id: Int!) {
  getPersistedInvoicePdf(id: $id) {
    type
    base64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPersistedInvoicePdfService extends Apollo.Query<GetPersistedInvoicePdfQuery, GetPersistedInvoicePdfQueryVariables> {
    override document = GetPersistedInvoicePdfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvoicePdfDocument = gql`
    query GetInvoicePdf($id: Int!) {
  getInvoicePdf(id: $id) {
    type
    base64
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoicePdfService extends Apollo.Query<GetInvoicePdfQuery, GetInvoicePdfQueryVariables> {
    override document = GetInvoicePdfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendInvoiceEmailDocument = gql`
    mutation SendInvoiceEmail($input: SendInvoiceEmailInput!) {
  sendInvoiceEmail(sendInvoiceEmailInput: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendInvoiceEmailService extends Apollo.Mutation<SendInvoiceEmailMutation, SendInvoiceEmailMutationVariables> {
    override document = SendInvoiceEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExportInvoicesAsCsvDocument = gql`
    mutation ExportInvoicesAsCsv($startDate: DateTime!, $endDate: DateTime!) {
  exportInvoicesAsCsv(startDate: $startDate, endDate: $endDate) {
    csv
    filename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExportInvoicesAsCsvService extends Apollo.Mutation<ExportInvoicesAsCsvMutation, ExportInvoicesAsCsvMutationVariables> {
    override document = ExportInvoicesAsCsvDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetInvoiceExportsDocument = gql`
    query GetInvoiceExports($year: String) {
  getInvoiceExports(year: $year) {
    filename
    year
    month
    sizeInBytes
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoiceExportsService extends Apollo.Query<GetInvoiceExportsQuery, GetInvoiceExportsQueryVariables> {
    override document = GetInvoiceExportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }