import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from "@sentry/angular";

Sentry.init({
  dsn: "https://1b505615f16522d570796a44c13b5e36@o4508748729286656.ingest.de.sentry.io/4508748742787152",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/perfo\.paldo\.de\/api/,
    /^https:\/\/perfo\.paldo\.de\/graphql/
  ],
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
