import { ChangeDetectionStrategy, Component, Input, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { Store } from '@ngxs/store';
import * as dayjs from 'dayjs';
import { ExportInvoicesAsCsv, ExportInvoicesAsPdf } from '../../../../state/ui.actions';
import { GetInvoiceExportsService } from '../../../../graphql/invoices-operations.generated';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';

// TODO: initial month selection should be highlighted in calendar.

@Component({
  selector: 'prf-accountant-export-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CalendarModule,
    FormsModule,
    ButtonModule,
    CardModule,
    ProgressSpinnerModule,
  ],
  templateUrl: './accountant-export-dialog.component.html',
  styleUrls: ['./accountant-export-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountantExportDialogComponent {
  private store = inject(Store);
  private getInvoiceExportsService = inject(GetInvoiceExportsService);
  private http = inject(HttpClient);
  private cdRef = inject(ChangeDetectorRef);

  downloadingFile: string | null = null;

  @Input({ required: true })
  initialDate: Date | undefined;

  selectedDate!: Date;

  loading$ = this.getInvoiceExportsService
    .fetch() // or .watch() if you want real-time updates
    .pipe(map((result) => result.loading));

  error$ = this.getInvoiceExportsService.fetch().pipe(map((result) => result.error));

  exports$ = this.getInvoiceExportsService
    .fetch()
    .pipe(map((result) => result.data?.getInvoiceExports));

  ngOnInit() {
    this.selectedDate = this.initialDate || new Date();

    // Fetch exports for the current year
    const currentYear = dayjs(this.selectedDate).format('YYYY');
    this.getInvoiceExportsService
      .watch({
        year: currentYear,
      })
      .refetch();
  }

  getMonthRange(): { start: Date; end: Date } {
    const date = dayjs(this.selectedDate);
    // TODO: FIX TIME OFFSET INCOMPATIB between frontend and backend ... ie 01.06.24 becomes 31.05.24 22:00:00 or similar...
    const start = date.startOf('month').add(6, 'hours').toDate();
    const end = date.endOf('month').toDate();

    return { start, end };
  }

  onExportCsv() {
    const { start, end } = this.getMonthRange();
    this.store.dispatch(
      new ExportInvoicesAsCsv({
        startDate: start,
        endDate: end,
      }),
    );
  }


  onExportPdf() {
    const { start, end } = this.getMonthRange();
    this.store.dispatch(
      new ExportInvoicesAsPdf({
        startDate: start,
        endDate: end,
      }),
    );
  }

  // export PDFs
  onDownloadExport(export1: any) {
    if (this.downloadingFile) return;

    const parts = export1.filename.split('-');
    const year = parts[2];
    const month = parts[3].replace('.pdf', '');

    this.downloadingFile = export1.filename;

    this.http
      // LOCAL DEV
      // .get(`http://localhost:3000/api/invoices/exports/${year}/${month}/download`, {
      .get(`/api/invoices/exports/${year}/${month}/download`, {
        responseType: 'blob',
        withCredentials: true,
      })
      .subscribe({
        next: (blob) => {
          saveAs(blob, export1.filename);
        },
        error: (error) => {
          console.error('Download failed:', error);
        },
        complete: () => {
          this.downloadingFile = null;
          this.cdRef.detectChanges();
        }
      });
  }

  isDownloading(filename: string): boolean {
    return this.downloadingFile === filename;
  }

  onYearChange() {
    const year = dayjs(this.selectedDate).format('YYYY');
    console.log('onYearChange - year', year);
    this.getInvoiceExportsService
      .watch({
        year,
      })
      .refetch();
  }

}
