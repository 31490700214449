<p-messages [(value)]="messages"
            [enableService]="false"
            [escape]="false"
            [closable]="false"></p-messages>

<prf-product-details [product]="entity"
                     [isCreate]="true"
                     [updateInProgress]="updateInProgress"
                     (createEntity)="handleCreateEntity($event)"
></prf-product-details>
