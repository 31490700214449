import { inject, Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from '@ngxs/store';
import {
  AddDeliveryToTransferredTargetWeek,
  ClearAllTransferredTargetWeekDeliveries,
  ClearDeliveryPlanningNewWeekDialogSelectedDelivery,
  ClearDeliveryPlanningOverviewSelectedDelivery,
  CloseNewWeekPlanningDialog,
  CloseSidebar, ExportInvoicesAsCsv, ExportInvoicesAsPdf,
  GetPersistedInvoicePdf,
  InitDynamicUiState,
  LoadAllEntities,
  LogoutUser,
  OpenCompactDeliverySlipDocument,
  OpenCompactDeliverySlipsDocumentCollection,
  OpenDeliverySlipDocument,
  OpenDeliverySlipsDocumentCollection,
  OpenInvoiceDocument,
  OpenNewWeekPlanningDialog,
  OpenSidebar,
  RemoveDeliveryFromTransferredTargetWeek,
  SetDeliveriesSelectedDetailsDelivery,
  SetDeliveriesSelectedDetailsDeliveryIsUpdating,
  SetDeliveriesTableRangeDates,
  SetDeliveryPlanningDragGroupingType,
  SetDeliveryPlanningNewWeekDialogSelectedDelivery,
  SetDeliveryPlanningOverviewSelectedCalendarWeek,
  SetDeliveryPlanningOverviewSelectedDelivery,
  SetInvoicesTableRangeDates,
  SetNewWeekPlanningCalendarWeek,
  ShowPlanNewDeliveryWeekDialog,
  ToggleSidebar,
  UpdateTransferredTargetWeekDelivery
} from './ui.actions';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { IDelivery, PerfoEntityType } from '@prf/shared/domain';
import { DeliveryDragGroupingType } from '../view-models/delivery-planning-draggable-week-overview-view-model.queries';
import { buildCurrentCalendarWeekOptions } from './ui.helper';
import { AuthService } from '../shared/services/auth/auth.service';
import {
  PlanNewDeliveryWeekDialogService
} from '../shared/services/dialogs/plan-new-delivery-week-dialog/plan-new-delivery-week-dialog.service';
import * as dayjs from 'dayjs';
import {
  GetCompactDeliverySlipPdfQueryVariables,
  GetCompactDeliverySlipPdfService,
  GetCompactDeliverySlipsCollectionPdfQueryVariables,
  GetCompactDeliverySlipsCollectionPdfService,
  GetDeliverySlipPdfQueryVariables,
  GetDeliverySlipPdfService,
  GetDeliverySlipsCollectionPdfQueryVariables,
  GetDeliverySlipsCollectionPdfService
} from '../graphql/deliveries-operations.generated';
import { map, tap } from 'rxjs';
import { LoadAllDeliveries } from './entities/deliveries.actions';
import { LoadMarkets } from './entities/markets.actions';
import { LoadRetailers } from './entities/retailers.actions';
import { LoadUsers } from './entities/users.actions';
import { LoadInvoices } from './entities/invoices.actions';
import { LoadVehicles } from './entities/vehicles.actions';
import { LoadIngredients } from './entities/ingredients.actions';
import { LoadProducts } from './entities/products.actions';
import {
  ExportInvoicesAsCsvService,
  GetInvoicePdfQueryVariables,
  GetInvoicePdfService, GetPersistedInvoicePdfService
} from '../graphql/invoices-operations.generated';

export interface UiStateModel {
  isSidebarOpen: boolean;
  newEntityDialog: NewEntityDialogUiState;
  deliveriesUi: DeliveriesUiState;
  invoicesUi: InvoicesUiState;
  deliveryPlanningOverview: DeliveryPlanningOverviewUiState;
  deliveryPlanningNewWeekDialog: DeliveryPlanningNewWeekDialogUiState;
}

type LocalStateModel = UiStateModel;
type LocalStateContext = StateContext<LocalStateModel>;

export interface NewEntityDialogUiState {
  visible: boolean;
  entityType: PerfoEntityType | null;
}

export interface CalendarWeekInfo {
  calendarWeekNumber: number | null;
  rangeDates: Date[] | undefined;
}

export interface CalendarWeekOption {
  weekNumber: number;
  fromDate: string; // YYYY-MM-DD
  toDate: string; // YYYY-MM-DD
  formattedFromDate: string;
  formattedToDate: string;
}

export interface DeliveriesUiState {
  tableRangeDates: Date[] | undefined;
  selectedDetailsDeliveryState: {
    deliveryId: number | null;
    isUpdating: boolean;
  };
}

export interface InvoicesUiState {
  tableRangeDates: Date[] | undefined;
  selectedDetailsInvoiceState: {
    invoiceId: number | null;
    isUpdating: boolean;
  };
}

export interface DeliveryPlanningOverviewUiState {
  dragGroupingType: DeliveryDragGroupingType;
  calendarWeeks: {
    options: CalendarWeekOption[];
    selectedWeekNumber: number | null;
  };
  selectedDetailsDelivery: IDelivery | null;
}

export interface DeliveryPlanningNewWeekDialogUiState {
  visible: boolean;
  sourceWeekInfo: CalendarWeekInfo;
  targetWeekInfo: CalendarWeekInfo;

  transferredTargetWeekDeliveries: IDelivery[];
  selectedDetailsDelivery: IDelivery | null;
}

export type DeliveryPlanningWeekType = 'source' | 'target';

// TODO MOVE
export function downloadCsv(csv: string, filename: string): void {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

// TODO: upon dialog close.
//      - CONFIRM CLOSE

@State<UiStateModel>({
  name: 'ui',
  defaults: {
    isSidebarOpen: true,
    newEntityDialog: {
      visible: false,
      entityType: null
    },
    deliveriesUi: {
      tableRangeDates: undefined,
      selectedDetailsDeliveryState: {
        deliveryId: null,
        isUpdating: false
      }
    },
    deliveryPlanningOverview: {
      dragGroupingType: 'BY_WEEKDAY',
      calendarWeeks: {
        options: [],
        selectedWeekNumber: null
      },
      selectedDetailsDelivery: null
    },
    deliveryPlanningNewWeekDialog: {
      visible: false,
      sourceWeekInfo: {
        calendarWeekNumber: null,
        rangeDates: undefined
      },
      targetWeekInfo: {
        calendarWeekNumber: null,
        rangeDates: undefined
      },
      transferredTargetWeekDeliveries: [],
      selectedDetailsDelivery: null
    },
    invoicesUi: {
      tableRangeDates: undefined,
      selectedDetailsInvoiceState: {
        invoiceId: null,
        isUpdating: false
      }
    }
  }
})
@Injectable()
export class UiState implements NgxsAfterBootstrap {
  private authService = inject(AuthService);
  private planNewDeliveryWeekService = inject(PlanNewDeliveryWeekDialogService);
  private getDeliverySlipPdfService = inject(GetDeliverySlipPdfService);
  private getCompactDeliverySlipPdfService = inject(GetCompactDeliverySlipPdfService);
  private getCompactDeliverySlipsCollectionPdf = inject(
    GetCompactDeliverySlipsCollectionPdfService
  );
  private getDeliverySlipsCollectionPdf = inject(GetDeliverySlipsCollectionPdfService);
  private getInvoicePdfService = inject(GetInvoicePdfService);
  private getPersistedInvoicePdfService = inject(GetPersistedInvoicePdfService);

  private exportInvoicesAsCsvService = inject(ExportInvoicesAsCsvService);

  ngxsAfterBootstrap(ctx: LocalStateContext): void {
    ctx.dispatch(new InitDynamicUiState());
  }

  // SELECTORS

  @Selector()
  static isSidebarOpen(state: LocalStateModel): boolean {
    return state.isSidebarOpen;
  }

  @Selector()
  static newEntityDialogState(state: LocalStateModel): NewEntityDialogUiState {
    return state.newEntityDialog;
  }

  @Selector()
  static deliveriesState(state: LocalStateModel): DeliveriesUiState {
    return state.deliveriesUi;
  }

  @Selector()
  static invoicesState(state: LocalStateModel): InvoicesUiState {
    return state.invoicesUi;
  }

  @Selector()
  static deliveryPlanningOverviewState(state: LocalStateModel): DeliveryPlanningOverviewUiState {
    return state.deliveryPlanningOverview;
  }

  @Selector()
  static deliveryPlanningNewWeekState(
    state: LocalStateModel
  ): DeliveryPlanningNewWeekDialogUiState {
    return state.deliveryPlanningNewWeekDialog;
  }

  // ACTIONS

  @Action(LoadAllEntities)
  loadAllEntities(ctx: LocalStateContext): void {
    ctx.dispatch([
      LoadRetailers,
      LoadMarkets,
      LoadProducts,
      LoadAllDeliveries,
      LoadUsers,
      LoadIngredients,
      LoadVehicles,
      LoadInvoices
    ]);
  }

  @Action(InitDynamicUiState)
  initDynamicUiState(ctx: LocalStateContext): void {
    ctx.setState(
      patch({
        deliveryPlanningOverview: patch(
          this.buildInitialDeliveryPlanningOverviewCalendarWeeksState()
        ),
        deliveryPlanningNewWeekDialog: patch(this.buildInitialDeliveryPlanningNewWeekDialogState()),
        deliveriesUi: patch({
          tableRangeDates: this.buildInitialTableRangeDates()
        }),
        invoicesUi: patch({
          tableRangeDates: this.buildInitialTableRangeDates(3, -2)
        })
      })
    );
  }

  @Action(OpenSidebar)
  openSidebar(ctx: LocalStateContext, _action: OpenSidebar): void {
    ctx.patchState({ isSidebarOpen: true });
  }

  @Action(CloseSidebar)
  closeSidebar(ctx: LocalStateContext, _action: CloseSidebar): void {
    ctx.patchState({ isSidebarOpen: false });
  }

  @Action(ToggleSidebar)
  toggleSidebar(ctx: LocalStateContext, _action: ToggleSidebar): void {
    const currentState = ctx.getState();
    ctx.patchState({ isSidebarOpen: !currentState.isSidebarOpen });
  }

  @Action(ShowPlanNewDeliveryWeekDialog)
  showPlanNewDeliveryWeekDialog(
    ctx: LocalStateContext,
    action: ShowPlanNewDeliveryWeekDialog
  ): void {
    console.log('showPlanNewDeliveryWeekDialog - ');
    this.planNewDeliveryWeekService.openPlanNewWeekDialog();
  }

  @Action(SetDeliveryPlanningDragGroupingType)
  setDeliveryPlanningDragGroupingType(
    ctx: LocalStateContext,
    action: SetDeliveryPlanningDragGroupingType
  ): void {
    ctx.setState(
      patch({
        deliveryPlanningOverview: patch({
          dragGroupingType: action.payload.dragGroupingType
        })
      })
    );
  }

  @Action(SetDeliveryPlanningNewWeekDialogSelectedDelivery)
  setDeliveryPlanningNewWeekDialogSelectedDelivery(
    ctx: StateContext<UiStateModel>,
    action: SetDeliveryPlanningNewWeekDialogSelectedDelivery
  ): void {
    ctx.setState(
      patch({
        deliveryPlanningNewWeekDialog: patch({
          selectedDetailsDelivery: action.payload.selectedDetailsDelivery
        })
      })
    );
  }

  @Action(ClearDeliveryPlanningNewWeekDialogSelectedDelivery)
  clearDeliveryPlanningNewWeekDialogSelectedDelivery(
    ctx: StateContext<UiStateModel>,
    action: ClearDeliveryPlanningNewWeekDialogSelectedDelivery
  ): void {
    ctx.setState(
      patch({
        deliveryPlanningNewWeekDialog: patch({
          selectedDetailsDelivery: null
        })
      })
    );
  }

  @Action(OpenNewWeekPlanningDialog)
  openNewWeekPlanningDialog(ctx: StateContext<UiStateModel>): void {
    ctx.setState(
      patch({
        deliveryPlanningNewWeekDialog: patch({
          // Clear on open.
          ...this.buildInitialDeliveryPlanningNewWeekDialogState(),
          visible: true
        })
      })
    );
  }

  @Action(CloseNewWeekPlanningDialog)
  closeNewWeekPlanningDialog(ctx: StateContext<UiStateModel>): void {
    ctx.setState(
      patch({
        deliveryPlanningNewWeekDialog: patch({
          // Clear on close.
          ...this.buildInitialDeliveryPlanningNewWeekDialogState(),
          visible: false
        })
      })
    );
    // do a hard reload to reload all regular deliveries etc. because NEW WEEK dialog currently might update/replace the global deliveries state with only a subset of all deliveries.
    window.location.reload();
  }

  @Action(SetDeliveryPlanningOverviewSelectedCalendarWeek)
  setDeliveryPlanningSelectedCalendarWeek(
    ctx: LocalStateContext,
    action: SetDeliveryPlanningOverviewSelectedCalendarWeek
  ) {
    ctx.setState(
      patch({
        deliveryPlanningOverview: patch({
          calendarWeeks: patch({
            selectedWeekNumber: action.payload.weekNumber
          })
        })
      })
    );
  }

  @Action(SetDeliveryPlanningOverviewSelectedDelivery)
  setDeliveryPlanningOverviewSelectedDelivery(
    ctx: StateContext<UiStateModel>,
    action: SetDeliveryPlanningOverviewSelectedDelivery
  ): void {
    ctx.setState(
      patch({
        deliveryPlanningOverview: patch({
          selectedDetailsDelivery: action.payload.selectedDetailsDelivery
        })
      })
    );
  }

  @Action(ClearDeliveryPlanningOverviewSelectedDelivery)
  clearDeliveryPlanningOverviewSelectedDelivery(ctx: StateContext<UiStateModel>): void {
    ctx.setState(
      patch({
        deliveryPlanningOverview: patch({
          selectedDetailsDelivery: null
        })
      })
    );
  }

  @Action(SetNewWeekPlanningCalendarWeek)
  setNewWeekPlanningCalendarWeek(ctx: LocalStateContext, action: SetNewWeekPlanningCalendarWeek) {
    const selectedDate = dayjs(action.payload.date);
    const weekNumber = selectedDate.isoWeek();
    const [startOfWeek, endOfWeek] = this.getWeekStartAndEnd(selectedDate);

    const updatedWeekInfo: CalendarWeekInfo = {
      calendarWeekNumber: weekNumber,
      rangeDates: [startOfWeek, endOfWeek]
    };

    const updatedState: Partial<DeliveryPlanningNewWeekDialogUiState> = {};

    if (action.payload.weekType === 'source') {
      updatedState.sourceWeekInfo = updatedWeekInfo;
    } else if (action.payload.weekType === 'target') {
      updatedState.targetWeekInfo = updatedWeekInfo;
    }

    ctx.setState(
      patch({
        deliveryPlanningNewWeekDialog: patch(updatedState)
      })
    );
  }

  @Action(SetInvoicesTableRangeDates)
  setInvoicesTableRangeDates(ctx: LocalStateContext, action: SetInvoicesTableRangeDates) {
    ctx.setState(
      patch({
        invoicesUi: patch({
          tableRangeDates: action.payload.rangeDates
        })
      })
    );
  }

  @Action(SetDeliveriesTableRangeDates)
  setDeliveriesTableRangeDates(ctx: LocalStateContext, action: SetDeliveriesTableRangeDates) {
    ctx.setState(
      patch({
        deliveriesUi: patch({
          tableRangeDates: action.payload.rangeDates
        })
      })
    );
  }

  @Action(SetDeliveriesSelectedDetailsDelivery)
  setDeliveriesSelectedDetailsDelivery(
    ctx: StateContext<UiStateModel>,
    action: SetDeliveriesSelectedDetailsDelivery
  ): void {
    const selectedDeliveryId = action.payload.deliveryId;

    ctx.setState(
      patch({
        deliveriesUi: patch({
          selectedDetailsDeliveryState: patch({
            deliveryId: selectedDeliveryId
          })
        })
      })
    );
  }

  @Action(SetDeliveriesSelectedDetailsDeliveryIsUpdating)
  setDeliveriesSelectedDetailsDeliveryIsUpdating(
    ctx: StateContext<UiStateModel>,
    action: SetDeliveriesSelectedDetailsDeliveryIsUpdating
  ): void {
    const isUpdating = action.payload.isUpdating;

    ctx.setState(
      patch({
        deliveriesUi: patch({
          selectedDetailsDeliveryState: patch({
            isUpdating: isUpdating
          })
        })
      })
    );
  }

  @Action(OpenDeliverySlipDocument)
  openDeliverySlipDocument(ctx: StateContext<UiStateModel>, action: OpenDeliverySlipDocument) {
    const delivery = action.payload.delivery;

    const variables: GetDeliverySlipPdfQueryVariables = {
      id: delivery.id
    };

    this.getDeliverySlipPdfService
      .fetch(variables)
      .pipe(
        map((result) => result?.data?.getDeliverySlipPdf),
        tap((data) => {
          openPdfInNewTab(data.base64);
        })
      )
      .subscribe();
  }

  @Action(OpenInvoiceDocument)
  openInvoiceDocument(ctx: StateContext<UiStateModel>, action: OpenInvoiceDocument) {
    const delivery = action.payload.delivery;

    const variables: GetInvoicePdfQueryVariables = {
      id: delivery.id
    };

    this.getInvoicePdfService
      .fetch(variables)
      .pipe(
        map((result) => result?.data?.getInvoicePdf),
        tap((data) => {
          openPdfInNewTab(data.base64);
        })
      )
      .subscribe();
  }


  @Action(GetPersistedInvoicePdf)
  getPersistedInvoicePdf(ctx: LocalStateContext, action: GetPersistedInvoicePdf) {
    return this.getPersistedInvoicePdfService.fetch({ id: action.payload.invoice.id }).pipe(
      tap((result) => {
        if (result.data && result.data.getPersistedInvoicePdf) {
          const { base64 } = result.data.getPersistedInvoicePdf;
          openPdfInNewTab(base64);
        } else {
          console.error('Failed to retrieve persisted invoice PDF');
          // TODO: Handle/show error to user
        }
      })
    );
  }

  @Action(OpenCompactDeliverySlipDocument)
  openCompactDeliverySlipDocument(
    ctx: StateContext<UiStateModel>,
    action: OpenCompactDeliverySlipDocument
  ) {
    const delivery = action.payload.delivery;

    const variables: GetCompactDeliverySlipPdfQueryVariables = {
      id: delivery.id
    };

    this.getCompactDeliverySlipPdfService
      .fetch(variables)
      .pipe(
        map((result) => result?.data?.getCompactDeliverySlipPdf),
        tap((data) => {
          openPdfInNewTab(data.base64);
        })
      )
      .subscribe();
  }

  @Action(OpenCompactDeliverySlipsDocumentCollection)
  openCompactDeliverySlipsDocumentCollection(
    ctx: StateContext<UiStateModel>,
    action: OpenCompactDeliverySlipsDocumentCollection
  ) {
    const deliveriesCollection = action.payload.deliveries;

    const variables: GetCompactDeliverySlipsCollectionPdfQueryVariables = {
      ids: deliveriesCollection.map((delivery) => delivery.id)
    };

    return this.getCompactDeliverySlipsCollectionPdf.fetch(variables).pipe(
      map((result) => result?.data?.getCompactDeliverySlipsCollectionPdf),
      tap((data) => {
        openPdfInNewTab(data.base64);
      })
    );
  }

  @Action(OpenDeliverySlipsDocumentCollection)
  openDeliverySlipsDocumentCollection(
    ctx: StateContext<UiStateModel>,
    action: OpenDeliverySlipsDocumentCollection
  ) {
    const deliveriesCollection = action.payload.deliveries;

    const variables: GetDeliverySlipsCollectionPdfQueryVariables = {
      ids: deliveriesCollection.map((delivery) => delivery.id)
    };

    return this.getDeliverySlipsCollectionPdf.fetch(variables).pipe(
      map((result) => result?.data?.getDeliverySlipsCollectionPdf),
      tap((data) => {
        openPdfInNewTab(data.base64);
      })
    );
  }

  @Action(AddDeliveryToTransferredTargetWeek)
  addDeliveryToTransferredTargetWeek(
    ctx: StateContext<UiStateModel>,
    action: AddDeliveryToTransferredTargetWeek
  ) {
    ctx.setState(
      patch({
        deliveryPlanningNewWeekDialog: patch({
          transferredTargetWeekDeliveries: append<IDelivery>([action.payload.delivery])
        })
      })
    );
  }

  @Action(UpdateTransferredTargetWeekDelivery)
  updateTransferredTargetWeekDelivery(
    ctx: StateContext<UiStateModel>,
    action: UpdateTransferredTargetWeekDelivery
  ) {
    ctx.setState(
      patch({
        deliveryPlanningNewWeekDialog: patch({
          transferredTargetWeekDeliveries: updateItem<IDelivery>(
            (delivery) => delivery.id === action.payload.updatedDelivery.id,
            action.payload.updatedDelivery
          )
        })
      })
    );
  }

  @Action(RemoveDeliveryFromTransferredTargetWeek)
  removeDeliveryFromTransferredTargetWeek(
    ctx: StateContext<UiStateModel>,
    action: RemoveDeliveryFromTransferredTargetWeek
  ) {
    ctx.setState(
      patch({
        deliveryPlanningNewWeekDialog: patch({
          transferredTargetWeekDeliveries: removeItem<IDelivery>(
            (delivery) => delivery.id === action.payload.deliveryId
          )
        })
      })
    );
  }

  @Action(ClearAllTransferredTargetWeekDeliveries)
  clearAllTransferredTargetWeekDeliveries(ctx: StateContext<UiStateModel>) {
    ctx.setState(
      patch({
        deliveryPlanningNewWeekDialog: patch({
          transferredTargetWeekDeliveries: []
        })
      })
    );
  }

  @Action(ExportInvoicesAsCsv)
  exportInvoicesAsCsv(
    ctx: StateContext<UiStateModel>,
    action: ExportInvoicesAsCsv
  ) {
    return this.exportInvoicesAsCsvService.mutate({
      startDate: action.payload.startDate,
      endDate: action.payload.endDate
    }).pipe(
      tap((result) => {
        if (result.data && result.data.exportInvoicesAsCsv) {
          const { csv, filename } = result.data.exportInvoicesAsCsv;
          downloadCsv(csv, filename);
        } else {
          console.error('Failed to generate CSV export');
          // TODO: Handle/show error to user
        }
      })
    );
  }

  @Action(ExportInvoicesAsPdf)
  exportInvoicesAsPdf( ctx: StateContext<UiStateModel>, action: ExportInvoicesAsPdf
  ) {
    const { startDate, endDate } = action.payload;
    console.log('exportInvoicesAsPdf - startDate, endDate', startDate, endDate);
  }

  @Action(LogoutUser)
  logoutUser(ctx: LocalStateContext, action: LogoutUser) {
    this.authService.logout(action.payload?.skipLogoutRequest);
  }

  // HELPER

  private buildInitialDeliveryPlanningOverviewCalendarWeeksState(): Pick<
    DeliveryPlanningOverviewUiState,
    'calendarWeeks'
  > {
    const weekOffset = -1;
    const currentDeliveryPlanningCalendarWeekOptions = buildCurrentCalendarWeekOptions(
      5,
      weekOffset
    );
    return {
      calendarWeeks: {
        options: currentDeliveryPlanningCalendarWeekOptions,
        selectedWeekNumber: currentDeliveryPlanningCalendarWeekOptions[-1 * weekOffset].weekNumber
      }
    };
  }

  private buildInitialDeliveryPlanningNewWeekDialogState(): Partial<DeliveryPlanningNewWeekDialogUiState> {
    const PLANNING_WEEK_OFFSET = 2;
    const today = dayjs();
    const sourceWeekInfo = this.createWeekInfo(today);
    const targetWeekInfo = this.createWeekInfo(today.add(PLANNING_WEEK_OFFSET, 'week'));

    return {
      sourceWeekInfo,
      targetWeekInfo,
      transferredTargetWeekDeliveries: [],
      visible: false
    };
  }

  private buildInitialTableRangeDates(
    totalNumOfWeeks = 1,
    startWeekOffset = 0
  ): Date[] | undefined {
    try {
      const currentCalenderWeeks = buildCurrentCalendarWeekOptions(
        totalNumOfWeeks,
        startWeekOffset
      );
      const firstWeek = currentCalenderWeeks[0];
      const lastWeek = currentCalenderWeeks[currentCalenderWeeks.length - 1];
      return [new Date(firstWeek.fromDate), new Date(lastWeek.toDate)];
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  private createWeekInfo(date: dayjs.Dayjs): CalendarWeekInfo {
    const calendarWeekNumber = date.isoWeek();
    const rangeDates = this.getWeekStartAndEnd(date);
    return { calendarWeekNumber, rangeDates };
  }

  // TODO: Refactor: Merge with identical method in plan-new-delivery-week-dialog.component.ts - into helper.
  //       Create weekday/date helper in general.
  private getWeekStartAndEnd(date: dayjs.Dayjs): Date[] {
    const startOfWeek = date.startOf('isoWeek').toDate();
    const endOfWeek = date.endOf('isoWeek').toDate();
    return [startOfWeek, endOfWeek];
  }
}

// TODO: extract into service
function openPdfInNewTab(base64String: string): void {
  const pdfBlob = base64ToBlob(base64String, 'application/pdf');
  const blobUrl = URL.createObjectURL(pdfBlob);
  window.open(blobUrl, '_blank');
}

function base64ToBlob(base64: string, type: string): Blob {
  const binaryString = window.atob(base64);
  const length = binaryString.length;
  const bytes = new Uint8Array(length);

  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: type });
}
