<ng-container *ngIf="viewModel$ | async as viewModel">
  <div class="flex flex-row">
    <div class="col">
      <p-calendar [ngModel]="viewModel.tableRangeDates"
                  (ngModelChange)="onTableRangeDatesSelect($event)"
                  selectionMode="range"
                  placeholder="Zeitraum wählen"
                  [showIcon]="true"
                  [showClear]="true"
                  [showButtonBar]="true"
                  [readonlyInput]="true"
                  [numberOfMonths]="2"
                  [selectOtherMonths]="true"
                  [showWeek]="true"
      ></p-calendar>

      <p-button
        styleClass="p-button-secondary ml-4"
        label="Steuerberater-Export"
        icon="pi pi-file-export"
        (click)="isAccountantExportDialogVisible = true"
      />

      <prf-data-table
        [rowData]="viewModel.invoices"
        [tableColumns]="TableConfig.columns"
        [loading]="loading"
        (selectionChange)="onEntitySelectionChange($event)" />
    </div>
    <div class="col col-data-details" *ngIf="selectedEntity">
      <div class="card">
        <p-tabView [activeIndex]="0">
          <p-tabPanel header="Details">
            <prf-invoice-details [entity]="selectedEntity"
                                 [updateInProgress]="updateInProgress"
                                 (entityChange)="handleUpdateEntity($event)" />
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>

  <p-dialog
    header="Steuerberater-Export"
    *ngIf="isAccountantExportDialogVisible"
    [visible]="true"
    (visibleChange)="onAccountantExportDialogVisibleChange($event)"
    [style]="{minWidth: '40vw'}">

    <prf-accountant-export-dialog [initialDate]="viewModel?.tableRangeDates?.[0]" />
  </p-dialog>
</ng-container>
