// TODO: check if null id makes sense here ... used for NEW OBJECTS... otherwise, make an ew type that excludes the ID
import { IProduct } from "./product.interface";
import { IMarket } from "./market.interface";
import { IIngredient } from "./ingredient.interface";
import { IRecipe } from "./recipe.interface";
import { IRetailer } from "./retailer.interface";
import { IDelivery, IDeliveryProduct } from "./delivery.interface";
import { IInvoice } from "./invoice.interface";
import { IUser } from "./user.interface";
import { IVehicle } from "./vehicle.interface";

export type EntityInterfaceMap = {
  product: IProduct;
  market: IMarket;
  ingredient: IIngredient;
  recipe: IRecipe;
  retailer: IRetailer;
  delivery: IDelivery;
  deliveryProduct: IDeliveryProduct;
  invoice: IInvoice;
  user: IUser;
  vehicle: IVehicle;
};

export type PerfoEntityType = keyof EntityInterfaceMap;

export interface IEntity {
  id: number;
}

export const ENTITY_TYPE_LABELS: Record<PerfoEntityType, string> = {
  product: "Artikel",
  market: "Markt",
  ingredient: "Zutat",
  recipe: "Rezept",
  retailer: "Händler",
  delivery: "Lieferung",
  deliveryProduct: "Liefermengen",
  invoice: "Rechnung",
  user: "Benutzer",
  vehicle: "Fahrzeug",
};

// Temporary fix. Makes id optional. Passing id to GraphQL create resolver leads to validation error.
export type CreatableEntity<T extends IEntity> = Omit<T, 'id'> & Partial<Pick<T, 'id'>>;

