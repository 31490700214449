import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsModule } from 'primeng/steps';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { PickListModule } from 'primeng/picklist';
import { PanelModule } from 'primeng/panel';
import { Select, Store } from '@ngxs/store';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { IDelivery, IMarket } from '@prf/shared/domain';
import { MarketsState } from '../../../../state/entities/markets.state';
import { Observable } from 'rxjs';
import { formatAddress } from '../../../formatters/entity.formatter';

@Component({
  selector: 'prf-markets-not-served-dialog',
  standalone: true,
  imports: [
    CommonModule,
    StepsModule,
    CalendarModule,
    FormsModule,
    ConfirmPopupModule,
    PickListModule,
    PanelModule,
    InputTextModule,
    SliderModule,
  ],
  templateUrl: './markets-not-served-dialog.component.html',
  styleUrls: ['./markets-not-served-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketsNotServedDialogComponent {
  private store = inject(Store);

  @Input({ required: true })
  servedDeliveries!: IDelivery[];

  @Input({ required: true })
  calendarWeekNumber!: number | null;

  @Select(MarketsState.items)
  markets$!: Observable<IMarket[]>;

  notServedMarkets: IMarket[] = [];

  ngOnChanges(): void {
    this.calculateMarketsNotServed();
  }

  private calculateMarketsNotServed(): void {
    const allMarkets = this.store.selectSnapshot(MarketsState.items);

    // Calculate markets not present in the list of servedDeliveries
    const notServedMarkets = allMarkets.filter(
      (market) => !this.servedDeliveries.some((delivery) => delivery.marketId === market.id),
    );
    // Log or handle the not served markets as needed
    this.notServedMarkets = notServedMarkets;
  }

  protected readonly formatAddress = formatAddress;
}
